html{
    scroll-behavior: smooth;
}

body{
    margin: 0;
    padding: 0;
    font-size: 16px;
    background: 
    linear-gradient(to top, rgba(255, 255, 255, 0.8), rgb(255, 255, 255, 0.9)),
    linear-gradient(to bottom, rgba(196, 190, 190, 0.5), rgba(223, 223, 223, 0)),
    url('./imagenes/bg.jpeg');
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.scope-one-regular {
    font-family: "Scope One", serif;
    font-weight: 500;
    font-style: normal;
  }
  

.header{
    width: 100%;
    height: 500px;
    background: 
    url('./imagenes/header.jpeg');
    background-position: center;
    background-size:cover;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.5);
    z-index: 2;    
}
.carouselheader {
    height: 600px; 
    overflow: hidden; 
}

.carouselheader .carousel-item {
    height: 100%; 
}

.carouselheader .carousel-item img {
    height:600px;
    object-position:center;
    object-fit: cover;
}

.carouselheader .carousel-inner{
    height: 600px;
}
.title{
    position: absolute;
    top: 40%;
    left: 4%;
    color: white;
    min-width: 45%;
    z-index: 10;
    text-shadow: 1px 1px 10px rgb(177, 177, 177);
}
.title button{
    min-width: 100px;
    margin-top: 100px;
    background-color: rgb(63, 63, 63, 0.7);
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    transition: color .1s ease-in-out;
    font-weight: bold;
}
.title button:hover{
    background: rgb(255, 255, 255, 0.7);
    box-shadow: 0 0 8px #ececec;
    color: rgb(48, 48, 48);
    font-weight: bold;
}

.title button a{
    color: white;
    text-decoration: none;
}

.navbar .nav-link {
    font-weight: 600;
    color: #000000 !important;
   
}

nav {
    z-index: 60;
    background-color: rgb(226, 226, 226);
}


.navbar .nav-link:hover {
    color:#555555e8 !important;

}

.cont{
    height: 500px;
    display: flex;
    align-items: center;
}
.content{
    min-height: 350px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background: linear-gradient(90deg, rgba(13,224,226,0.4) 0%, rgba(0,255,98,0.6) 100%);
}

h2{
    color: #000000;
}
p{
    font-size: 1.2em;
    z-index: 20;
}

.w-md-75{
    text-align: left;
}
.home{
    background-image: url(./imagenes/14.jpeg);
    background-position:top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.home::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.315);
    z-index: 2;
}

.iconarrow{
    font-size: 28px;
    animation: arrow 2s ;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes arrow {
    0%{
        transform: translateY(-1px);
        color: rgb(27, 27, 27);
    }
    100%{
        transform: translateY(10px);
        color:#d5d5d5;
    }
    }

.collapse-container{
    z-index: 10;
}

.buttinfo{
    width: 500px;
    min-height: 180px;
    background-color: rgba(255, 255, 255, 0.979);
    border-radius: 12px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.829);
    cursor: pointer;
    font-size:1em;
    z-index: 10;
}

.buttinfo:hover{
    background-color: #ffffffd7;

}

.lugares{
    background-image: url(./imagenes/13.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 350px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.places{
    height: 500px;
    width: 500px;
    z-index: 100;
    margin-bottom: 20px;
}

.carousel-caption{
    color: white;
    z-index: 50;
}
.carousel-item{
    max-height: 500px;
    box-shadow: 1px 1px 10px;
}
.carousel-caption h5{
    font-size: 1.3em;
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.507);
}
.zindexc{
    z-index: 40;
    color: rgb(0, 0, 0);
}
.zindex{
    z-index: 40;
    cursor: pointer;
    width: 320px;
    margin: 10px;
}
.zindex:hover{
    transform: scale(1.05);
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}
.zindex img:hover{
    box-shadow: 0 0 8px white;
}
.lugares::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.651);
}
.lugares .carousel-inner{
    box-shadow: 1px 1px 12px rgb(0, 0, 0);
}
.boxcol{
    width: 480px;
    min-height: 200px;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.829);
    cursor: pointer;
    font-size:1em;
    z-index: 10;
    font-weight: 500;
}
.boxinfo1{
    width: 400px;
    min-height: 200px;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.829);
    cursor: pointer;
    font-size:0.9em;
    z-index: 10;
}
.boxinfo1:hover{
    background-color: #ffffffd7;

}

.boxinfo2{
    width: 600px;
    min-height: 200px;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.829);
    cursor: pointer;
    font-size:0.9em;
    z-index: 10;
}

.boxinfo2:hover{
    background-color: #ffffffd7;

}
.images{
    width: 100%;
    min-height: 400px;
}
.images img{
    box-shadow: 2px 2px 12px black;
    margin: 5px;
    overflow: hidden;
}
.video{
    width: 600px;
    height: 400px;
    z-index: 200;
    margin-bottom: 40px;
    margin-top: 20px;
}

lite-youtube{
    width: 100%;
    height: 100%;
}

.circuitos{
    background-color: rgba(92, 244, 255, 0.432);
    position: relative;
    min-height: 500px;
    z-index: 2;
    

}


.circuitos h2{
    color: rgb(0, 0, 0);
    position: relative;
    z-index: 500;
    padding: 20px;
}
.price{
    width: 200px;
    background-color: white;
    text-decoration: none;
    border:1px solid black;
    margin-bottom: 15px;
}
.price a{
    text-decoration: none;
    color: black;
}
.price:hover{
    background-color: rgba(0, 242, 250, 0.425);
    text-decoration: nrgba(2, 196, 179, 0.301);

}
.forms{
    background-image: url(./imagenes/7.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.forms::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);

}

form{
    width: 400px;
    z-index: 10;
}
.forms h2 {
    color: white;
    z-index: 20;
}
.dat{
    z-index: 20;
    color: white;
}
.buttonsub{
    background: white;
    border: none;
    width: 120px;
    z-index: 20;
    font-weight: 600;
    margin-bottom: 60px;
}
.buttonsub:hover{
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    color: rgb(44, 54, 53);
    box-shadow: 1px 1px 10px rgb(90, 90, 90);
    background-color: rgba(137, 255, 226, 0.695);
}

.atm{
    min-height: 200px;
    background-color: rgba(228, 228, 228, 0.712);
    display: flex;
    justify-content: center;;
}
.atm p{
    font-size: 1.5em;
    font-weight: 600;
    margin: 50px;
}
.footer{
    background-color: rgb(228, 228, 228);
    min-height: 200px;
    width: 100%;
}

.footer .nav-link{
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 0.9em;
}
.footer .nav-link:hover{
   color: rgb(2, 204, 144);
}

.icon{
    font-size: 1.6em;
    padding: 15px;
    color: black;
}
.icon:hover{
    text-shadow: 0 0 6px  rgba(0, 255, 98, 0.781);
}



@media screen and (max-width: 1024px) {
    .header {
        height: 400px;
        background-size: contain;
    }
    
    .title {
        top: 30%;
        left: 2%;
        min-width: 70%;
        font-size: 1.2em;
    }

    .boxcol, .boxinfo1, .boxinfo2 {
        width: 100%;
        margin: 10px 0;
    }
}

@media screen and (max-width: 910px) {
    .header{
        width: 100%;
        height: 600px;
        background: 
        url('./imagenes/header.jpeg');
        background-position: center;
    }
    .title{
        margin-top: 80px;
    }
    .navbar-collapse{
        width: 100%;
        margin-left: 30px;
    }
    .navbar-toggler{
        margin-right:70px;
        color: black;
    }

    .images img{
       margin:10px;
    }
    .boxcol{
        width: 450px;
    }
    .buttinfo{
        width: 450px;
    }
    .boxinfo1{
    width: 260px;
    }
    .boxinfo2{
        width: 400px;
    }
    .navbar-brand{
        margin-left: 30px;
    }
    .boxcir{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .car{
        width: 100%;
        height: 100%;
    }
    .atm img{
        width: 120px;
        height: 110px;
    }
   
}

@media screen and (max-width: 768px) {
    body{
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .header{
        width: 100%;
        height: 550px;
        background: 
        url('./imagenes/header.jpeg');
        background-position: center;
    }

    .navbar-collapse{
        width: 100%;
        margin-left: 70px;
    }
    .navbar-brand{
        margin-left: 80px;
    }
    .boxinfo2{
        width: 345px;
        height: 440px;
        padding: 5px;
    }
    .atm img{
        width: 110px;
        height: 100px;
    }
    .video{
        width: 400px;
        height: 450px;
        }

}

@media screen and (max-width: 600px) {

    .title {
        top: 40%;
        left:14%;
        font-size: 0.7em;
        text-align: center;
    }
    .images img{
        margin: 10px;
        width:280px;
        height: 290px;
    }
    
    .icon {
        font-size: 1em;
        margin-left: 10px;
    }
    .places{
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 500px) {
    .navbar-collapse{
        width: 100%;
        margin-left: 30px;
    }
    .navbar-brand{
        margin-left: 20px;
    }
    .boxinfo2{
        width: 280px;
    }
    .video{
    width: 300px;
    height: 300px;
    }
    .zindex img{
        width: 280px;
        height: 280px;
        margin: 10px;
    }
    .places{
        width: 360px;
        height: 360px;
    }
    .icon {
        font-size: 1.5em;
        padding:10px;
        text-shadow: 1px 1px 10px rgb(202, 202, 202);
    }

    .buttonsub{
        color: rgb(0, 0, 0);
    }
    .buttinfo{
        color: black;
        width: 350px;
    }
 
    .footer{
        height: 300px;
        width: 100%;
    }
    .footer .nav-link{
        font-size: 1.1em;
    }
    .boxinfo2{
        width: 310px;
        height: 470px;
        padding: 5px;
    }
    .boxcol{
        width: 340px;
    }
    .atm{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}



@media screen and (max-width: 390px) {
    .places{
        width: 300px;
        height: 300px;
    }
    .buttinfo{
        width: 300px;
    }

}